import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import history from "./withTracker";

import Home from './pages/Home';
import Editions from './pages/Editions';
import History from './pages/History';
import Contact from './pages/Contact';

function Routes() {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={ Home } />
        <Route exact path="/editions" component={ Editions } />
        <Route exact path="/history" component={ History } />
        <Route exact path="/contact" component={ Contact } />
        <Route path="*" component={ Home } />
      </Switch>
    </Router>
  );
}

export default Routes;