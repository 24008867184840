import React from 'react';

import './style.css';
import emailIcon from '../../assets/icons/email.svg';
import facebookIcon from '../../assets/icons/facebook.svg';
import phoneIcon from '../../assets/icons/phone.svg';
import whatsappIcon from '../../assets/icons/whatsapp.svg';
import dsicariLogo from '../../assets/dsicariLogo.svg';
import bigBoxLogo from '../../assets/bigBoxLogo.svg';

export default function Footer(){
  return(
    <>
      <div className="footer-container">
        <div className="footer-content">
          <div className="footer-logo footer-margin__top">
            <span>Jornal Nossa Cidade</span>
          </div>
          <div className="footer-address default-page-text">
            <address>R. Achiles Audi, 71, Centro - Cerquilho - São Paulo - CEP 18520-000</address>
          </div>
          <div className="footer-contact default-page-text">
            <a href="tel:+551533843800"><img src={phoneIcon} alt="" />Telefone: 15 3384 3800</a>
            <a href="https://wa.me/5515998608544" target="_blank"><img src={whatsappIcon} alt="" />WhatsApp: 15 99860 8544</a>
            <a href="mailto:nossacidadejornal@gmail.com"><img src={emailIcon} alt="" />E-mail: nossacidadejornal@gmail.com</a>
            <a href="https://www.facebook.com/Jornal-Nossa-Cidade-679576288738210"><img src={facebookIcon} alt="" />Facebook: jornalnossacidade</a>
          </div>
          <div className="footer-developed-by default-page-text">
            <span>Desenvolvido por:</span>            
            <div className="footer-developed-by-logo">
              <a href="https://dsicari.com.br" target="_blank"><img src={dsicariLogo} alt="" /></a>
              <a href="https://bigboxinfostore.com.br" target="_blank"><img src={bigBoxLogo} alt="" /></a>
            </div>
            <span>© Todos os direitos reservados</span>
          </div>
        </div>
      </div>      
    </>
  )
}