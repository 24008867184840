import React from 'react';

import './style.css';

export default function Partner({ imageUrl, name, partnerUrl}){
  return(
    <div className="partner-container">
      <a href={partnerUrl} target="_blank">
        <div className="partner-box">
          <img src={imageUrl} alt=""/>
        </div>
        <span className="partner-name">{name}</span>
      </a>
    </div>
  );

}