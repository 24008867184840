import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import Header from '../../components/Header';
import Edition from '../../components/Edition';
import Footer from '../../components/Footer';
import Partner from '../../components/Partner';

import './style.css';

import appStoreAvailable from '../../assets/appStoreAvailable.png';
import googlePlayAvailable from '../../assets/googlePlayAvailable.png';

function Home(){

  const [newestEdition, setNewestEdition] = useState({});
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    //TODO: error handling
    async function getNewestEdition(){
      const response = await api.get('/editions/newest');
      setNewestEdition(response.data);
    }

    //TODO: error handling
    async function getPartners(){
      const response = await api.get('/partners');
      setPartners(response.data);
    } 

    getNewestEdition();
    getPartners();
  }, []);

  return(
    <>
      <Header />

      <div className="home-content">
        <Edition 
          thumbnailUrl={newestEdition.thumbnailUrl}
          fileUrl={newestEdition.fileUrl}
          publicationDate={newestEdition.publicationDate}
          title={newestEdition.title}
          />
      </div>

      <div className="home-partners">
        <div className="home-partners-content global__content-width">
          {partners.length && partners.map((partner) => {
            return (<Partner 
              key={partner.id}
              imageUrl={partner.imageUrl}
              name={partner.name}
              partnerUrl={partner.partnerUrl}
            />)
          })}
        </div>
      </div>

      <div className="home-app-store">
        <div className="home-app-store-content global__content-width">
          <span>Anuncie conosco e atinja milhares de pessoas, entre em contato agora mesmo!</span>
          <div className="home-app-store-logos">
            <div className="home-app-store-logos-google">
              <a href="https://play.google.com/store/apps/details?id=br.com.jornalnossacidade" target="_blank"><img className="home-app-store-logo" src={googlePlayAvailable} alt=""/></a>
            </div>
            <div className="home-app-store-logos-apple">
              <a href="#" target="_blank"><img className="home-app-store-logo" src={appStoreAvailable} alt=""/></a>
              <span>EM BREVE!</span>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Home;