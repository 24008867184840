import React, { useState } from 'react';
import messageApi from '../../services/messageApi';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import GoogleMapReact from 'google-map-react';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import './style.css';
import markerIcon from '../../assets/icons/marker.png';

const AnyReactComponent = ({ text }) => <div><img src={markerIcon} className="map-marker-icon"/><b>{text}</b></div>;

export default function Contact(){

  const MySwal = withReactContent(Swal);  

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: ""
  });

  const handleChange = (prop) => (event) => {
    setFormData({ ...formData, [prop]: event.target.value });
    console.log(formData);
  };

  const defaultProps = {
    center: {      
      lat: -23.16319765838126,
      lng: -47.74538311164205
    },
    zoom: 17
  };

  function sendWhatsAppMessage(){  
    let message = "";
  
    if(formData.name !== ""){
      message += "Nome: " + formData.name + "%0a";
    }
  
    if(formData.email !== ""){
      message += "Email: " + formData.email + "%0a";
    }
  
    if(formData.phone !== ""){
      message += "Telefone: " + formData.phone + "%0a";
    }
  
    if(formData.message !== ""){
      message += "Mensagem: " + formData.message + "%0a";
    }
  
    message.replace(" ", "%20");
    console.log(message);
  
    window.open("https://wa.me/5515998608544?text=" + message , "_blank");
  }

  async function sendMessage(){

    MySwal.fire({
      title: <p>Enviando Mensagem</p>,          
      onOpen: () => {
        MySwal.showLoading();
      }
    });

    try{
      const response = await messageApi.post('/message/4ee71183-be47-4f92-8381-8861f5c553bd', formData);
      
      MySwal.close();  
      MySwal.fire({
        icon: 'success',
        title: <p>Mensagem Enviada!</p>,          
        timer: 2000,
        showConfirmButton: true
      });
    }
    catch(error){
      MySwal.close();
      MySwal.fire({
        icon: 'error',
        title: <p>Não foi possível enviar a mensagem</p>,
        showConfirmButton: true
      });
    }
  }
  
  return(
    <>
      <Header />

      <div className="contact-and-localization-container">     

        <div className="contact-container">
          <div className="contact-content global__content-width">
            <div className="default-page-title">Contato</div>

            <label htmlFor="name" className="contact-label default-page-text">Nome</label>
            <input 
              type="text" 
              name="name" 
              id="contact-name" 
              className="contact-input default-page-text" 
              value={formData.name}  
              onChange={handleChange('name')} />
            
            <label htmlFor="email" className="contact-label default-page-text">E-mail</label>
            <input 
              type="email" 
              name="email" 
              id="contact-email" 
              className="contact-input default-page-text"                
              value={formData.email}  
              onChange={handleChange('email')} />
            
            <label htmlFor="phone" className="contact-label default-page-text">Telefone</label>
            <input 
              type="tel" 
              name="phone" 
              id="contact-phone" 
              className="contact-input default-page-text"  
              value={formData.phone}  
              onChange={handleChange('phone')} />
            
            <label htmlFor="message" className="contact-label default-page-text">Mensagem</label>            
            <textarea 
              name="message" 
              id="contact-message" 
              className="contact-textarea default-page-text" 
              rows="5"  
              value={formData.message}  
              onChange={handleChange('message')} />
            
            <div className="contact-buttons">
              <button type="button" id="button-whatsapp" className="contact-button default-page-text cursor-pointer" onClick={() => sendWhatsAppMessage()}>Via WhatsApp</button>
              <button type="button" id="button-send-message" className="contact-button default-page-text cursor-pointer" onClick={() => sendMessage()}>Enviar Mensagem</button>
            </div>
          </div>
        </div>

        <div className="localization-container">
          <div className="localization-content global__content-width">
            <div className="default-page-title">Localização</div>
            <div style={{ height: '50vh', width: '100%' }}>
              <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyB8i85M2xRj2lUuxb_S_I0n1WlGxgPy5K0" }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
              >
                <AnyReactComponent
                  lat={defaultProps.center.lat}
                  lng={defaultProps.center.lng}
                  text="Jornal Nossa Cidade"
                />
              </GoogleMapReact>
            </div>
            <div className="localization-address default-page-text">
              <address> R. Achiles Audi, 71, Centro - Cerquilho - São Paulo - CEP 18520-000</address>
            </div>        
          </div>
        </div>

      </div>

      <Footer />
    </>
  );
}