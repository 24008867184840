import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Edition from '../../components/Edition';

import './style.css';

export default function Editions(){

  const [editions, setEditions] = useState([]);

  useEffect(() => {
    //TODO: error handling
    async function getEditions(){ 
      try{
        const response = await api.get('/editions');
        setEditions(response.data);   
      }
      catch(error){ }
    }

    getEditions();
  }, []);

  return(
    <>
      <Header />
      
      <div className="default-page-title">Edições Anteriores</div>
      
      <div className="editions-content">
        {editions && editions.map((edition) =>{
          return(
            <Edition
              key={edition.id}
              thumbnailUrl={edition.thumbnailUrl}
              fileUrl={edition.fileUrl}
              publicationDate={edition.publicationDate}
              title={edition.title}
            />
          );
        })}
      </div>

      <Footer />
    </>
  );
}