import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import './style.css';

export default function History(){

  return(
    <>
      <Header />
      
      <div className="history-container">

        <div className="default-page-title">História</div>

        <div className="history-content default-page-text global__content-width">
          <div className="history-text">
            O jornal Nossa Cidade é o “caçula” dos jornais da cidade. Fundado em 20 de julho de 1.997, alcançou um sucesso que atravessou os limites do município e hoje é uma das mídias de maior índice de leitura e retorno comercial de toda a região.
          </div>
          <div className="history-text">
            No início, circulou com 12 páginas, com periodicidade quinzenal. Dois anos depois, já estava com 24 páginas e a circulação passou a ser trimensal (3 edições ao mês), sempre às sextas-feiras, sem que seus anunciantes tivessem qualquer custo adicional por isto.
          </div>
          <div className="history-text">
            E esse sucesso se deve ao pioneirismo que já marcou o Nossa Cidade desde a sua fundação: a ousadia em lançar um periódico em formato diferente de tudo o que havia, fugindo da mesmice, inovando também na impressão: foi o primeiro jornal a ser impresso em cores, o primeiro a ter um site, o primeiro a ter um programa cultural, o primeiro a lançar um caderno exclusivo de Classificados.
            Veio então circulação regional: leitores de cidades vizinhas passaram a reivindicar o recebimento do jornal e o Nossa Cidade atravessou as fronteiras cerquilhenses, hoje circulando em:
            Jumirim, Laranjal Paulista e Tietê.
          </div>
          <div className="history-text">
            O jornal não tem um nome a vangloriar, tem um trabalho. Um trabalho sério, honesto desenvolvido por uma equipe competente de sete profissionais, cada qual desempenhando um papel fundamental nas áreas comercial, redação, distribuição e administração.
            Todos, sem exceção, merecem hoje os parabéns por alcançar essa marca e, em tão pouco tempo, tornar-se líder no mercado local, e uma das mídias de maior sucesso em toda a região.
          </div>
          <div className="history-text">
            O jornal Nossa Cidade tem como jornalista responsável o seu fundador, José Geraldo Doriguello (MtB 22981), formado em jornalismo pela Universidade Metodista de Piracicaba e pós-graduado em Comunicação pela Faculdade de Comunicação Social Cásper Líbero, de São Paulo.
          </div>
        </div>
        
      </div>

      <Footer />
    </>
  );
}