function limitString(string, length){
  if(string === undefined){
    return "";
  }

  if(string.length > length){
    return string.substring(0, length-3) + "...";
  }

  return string;
}

export { limitString }