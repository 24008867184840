import React from 'react';
import { convertAAMMDDToString } from '../../utils/TimeDateUtils';
import { limitString } from '../../utils/StringUtils';

import './style.css';

export default function Edition({thumbnailUrl, publicationDate, title, fileUrl}){

  

  return(
    <div className="edition-content global__content-width">
      <div className="edition-thumbnail">
        <a href={fileUrl} target="_blank"><img src={thumbnailUrl} alt=""/></a>
      </div>
      <div className="edition-details">
        <div className="edition-publication-date">
          <span>Cerquilho, {convertAAMMDDToString(publicationDate)}</span>
          <div className="edition-publication-date-hr"></div>
        </div>
        <div className="edition-title">
          {limitString(title, 75)}
        </div>
        <div className="edition-read-it">
          <a href={fileUrl} target="_blank">Acesse a edição aqui</a>
        </div>
      </div>
    </div>
  );
}