function convertAAMMDDToString(date){
  if(date === undefined){
    return;
  }
  
  let d = new Date(date);
  let month = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
  return `${d.getDate()} de ${month[d.getMonth()].toLowerCase()} de ${d.getFullYear()}`
}

export { convertAAMMDDToString }