import React from 'react';
import { useHistory } from 'react-router-dom';

import './style.css';
import logo from '../../assets/jncHeaderLogo.svg';

export default function Header(){
  
  const history = useHistory();

  return(
    <>
      <div className="header-container">
        <div className="header-logo">
          <img src={logo} alt=""/>
          <span>Desde 1997</span>
        </div>
      </div>
      <div className="header-menu-container">
        <div className="header-menu">
          <span onClick={() => history.push('/')} className="header-menu-item cursor-pointer">Home</span>
          <span onClick={() => history.push('/editions')} className="header-menu-item cursor-pointer">Edições</span>
          <span onClick={() => history.push('/contact')} className="header-menu-item cursor-pointer">Contato</span>
          <span onClick={() => history.push('/history')} className="header-menu-item cursor-pointer">História</span>          
        </div>
      </div>
    </>
  )
}